import React from 'react'
import "./Navbar.css"
import { Link, NavLink} from 'react-router-dom';

const Navbar = () => {
    return (
            <nav class="navbar navbar-expand-lg fixed-top">
                <div class="container">
                    <a class="navbar-brand"><Link to={'/'}>Regee Tamang</Link></a>
                    <button class="navbar-toggler hidden-sm-up " type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse justify-content-end" id="navbarResponsive">                        
                        <div class="navbar-nav d-flex align-items-end" >                       
                            <a class="nav-item nav-link"> <NavLink exact activeClassName="active" to={'/'}>Home</NavLink></a>
                            <a class="nav-item nav-link"><NavLink  activeClassName="active" to={'about'}>About</NavLink></a>
                            <a class="nav-item nav-link"> <NavLink activeClassName="active" to={'music'}>Music</NavLink></a>
                            <a class="nav-item nav-link"> <NavLink activeClassName="active" to={'contact'}>Contact</NavLink></a>
                        </div>
                    </div>
                </div>
            </nav>
    )
}

export default Navbar