import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import "./Contact.css"


const Contact = () => {
  const form = useRef();
  const [isFormSent, setFormStatus] = useState();
  const [nameValue, setNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [messageValue, setMessageValue] = useState('');
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_zw3gbxo', 'template_bqh4pmo', form.current, 'QDA7L2jOnI2LgYngr')
      .then((result) => {
        console.log(result.text);
        setFormStatus(true);
        setNameValue('');
        setEmailValue('');
        setMessageValue('');

      }, (error) => {
        console.log(error.text);
        setFormStatus(false);
      });
  };

  return (

    <div class="form-box">
      <h4>Contact</h4>
      <form ref={form} onSubmit={sendEmail}>
        <div class="form-group">
          <label for="name"><small>Name</small></label>
          <input class="form-control" type="text" name="from_name" required="true" placeholder='Your Name' onChange={(e) => setNameValue(e.target.value)} value={nameValue} />
        </div>
        <div class="form-group">
          <label for="email"><small>Email</small></label>
          <input class="form-control" type="email" name="Email" required="true" placeholder='name@example.com' onChange={(e) => setEmailValue(e.target.value)} value={emailValue} />
        </div>
        <div class="form-group">
          <label for="message"><small>Message</small></label>
          <textarea class="form-control" name="message" required="true" placeholder='Please write your message to Regee here.' onChange={(e) => setMessageValue(e.target.value)} value={messageValue}></textarea>
        </div>
        {
          isFormSent === true ? <div className='alert alert-success alert-dismissible'> <a href='#' class='close' data-dismiss="alert" aria-label='close'>&times;</a>Your message has been sent successfully.
          </div> : isFormSent === false ? <div className='alert alert-danger'>Your message was not sent. Please submit your form again</div> : <></>
        }
        <input class="btn btn-primary" type="submit" value="Submit" />
      </form >
      <br />
    </div >
  )
}

export default Contact