import React from 'react'
import "./Footer.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import{faFacebook, faInstagram, faSpotify, faYoutube, faApple}from "@fortawesome/free-brands-svg-icons";


const Footer = () => {
  return (
    <footer class="footer justify-content-between align-items-center py-1 fixed-bottom bg-light">
    <div className="container">
      <div className="row">
        <div className="col-md-4 justify-content-center">
          <div className='copy-wright'>
            <p>Copyright © 2023 Regee Tamang</p>
          </div>
        </div>
        <div className="col-md-4 ">
          <div class="social-media">
            <ul>
              <li><a href="https://www.facebook.com/regee.tamang/"  className="facebook" target="_blank"> <FontAwesomeIcon icon={faFacebook} size="2x" /> </a></li>
              <li><a href="https://www.instagram.com/regee_tamang/" className="instagram" target= "_blank"><FontAwesomeIcon icon={faInstagram} size="2x" /></a></li>
              <li><a href="https://www.youtube.com/@regeetamang316" className='youtube' target="_blank"><FontAwesomeIcon icon={faYoutube} size="2x" /></a></li>
              <li><a href="https://open.spotify.com/artist/6L9ouJ8QdsP3E9aUW9VMQ6?si=izLoC6JhR2SAW-BLSkUvoA" className="spotify" target="_blank"><FontAwesomeIcon icon={faSpotify} size="2x" /></a></li>
              <li><a href="https://music.apple.com/us/artist/regee-tamang/1585973545" className="apple"target="_blank"><FontAwesomeIcon icon={faApple} size="2x" /></a></li>
            </ul>
          </div>
        </div>
        <div className="col-md-4 ">
          <div class="owner">
            <p>Developed by Doma Lama</p>
          </div>
        </div>
      </div>
    </div>
    </footer>
  )
}
export default Footer