import React from 'react'
import "./About.css"
import "./Home.css"

const About = () => {
  return (
    <div className='about-image'>
      <div className="row">
        <div className="left col-sm-7">
        </div>
        <div className="right col-sm-5">
          <p className="paragraph1"><small>Regee is a New York based guitarist who has lived a life devoted to music. His journey began in his childhood,
            where his deep-seated passion for music was evident from the moment he first picked up a guitar.
            His early memories are filled with the melodious strumming of chords, foreshadowing his destined path as a musician.
            In 2013, Regee introduced the world to "Times a Lie," a post-hardcore metal band he created with his friends. Together, they created
            powerful music that resonated with fellow metal enthusiasts, characterized by raw emotion and a spirit of rebellion.
            Though the band eventually disbanded, their music left an indelible mark on their listeners.
            In 2021, Regee's musical journey took another turn when he released "Arko Junima," his debut single.
            This marked his foray into experimental genres, showcasing his versatility and boundary-pushing creativity.
            Beyond his music career, Regee is a dedicated music student at Brooklyn College, continually honing his skills
            and expanding his musical knowledge.</small></p>
        </div>
      </div>
    </div>
  )

}

export default About