import React from 'react'
import "./Home.css"

const Home = () => {
  return (
    <div>
      <div className='home-page'>
        <h5>Guitarist, Singer, and Songwriter</h5>
      </div>
    </div>
  );
}

export default Home