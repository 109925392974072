import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Components/Layout';
import Contact from './Pages/Contact';
import Music from './Pages/Music';
import About from './Pages/About';
import Home from './Pages/Home';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />} >
            <Route index element={<Home/>}/>
            <Route path='contact' element={<Contact/>} />
            <Route path='about' element={<About/>}/>
            <Route path ='music' element={<Music/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
